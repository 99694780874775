import Bugsnag from "@bugsnag/js";
import axios from "axios";
import React, { Component } from "react";
import cookie from "react-cookies";
import CurrencyFormat from "react-currency-format";
import SEO from "react-seo-component";
import {
  Button,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import Alert from "reactstrap/lib/Alert";
import LatLngToAddress from "../../components/common/LatLngToAddress";
import constants from "../../Constants";

const baseURL = constants.baseURL;
class Section extends Component {
  constructor() {
    super();

    let orderid = "";
    let email = "";
    let paynow = "";
    let success = "";
    let cancel = "";
    try {
      const queryParams = new URLSearchParams(window.location.search);
      orderid = queryParams.get("orderid") || "";
      email = queryParams.get("email") || "";
      paynow = queryParams.get("paynow") || "";
      success = queryParams.get("success") || "";
      cancel = queryParams.get("cancel") || "";
    } catch (error) {
      Bugsnag.notify(error);
    }

    this.state = {
      loading: false,
      ringDriver: false,
      trackedOrderValid: false,
      apierror: "",
      orderid,
      email,
      paynow,
      orderdetails: {},
      success,
      cancel,
      pdfinvoice: "",
    };
  }
  validStepOne() {
    const { orderid, email } = this.state;
    return !(orderid?.length < 2 || email?.length < 5);
  }

  trackOrder(showBankingDetails) {
    const { orderid, email } = this.state;

    this.setState({
      loading: true,
      apierror: "",
      showBankingDetails: undefined,
    });
    axios
      .post(baseURL + "/track-order", {
        orderid,
        email,
      })
      .then((response) => {
        this.setState(
          {
            orderdetails: response.data,
            trackedOrderValid: response.data?.orderid?.length > 0,
            showBankingDetails: showBankingDetails,
          },
          () => {
            if (response.data?.orderid?.length > 0) {
              cookie.save(
                "currentOrder",
                `email=${response.data.email}&orderid=${response.data.orderid}`,
                {
                  path: "/",
                  maxAge: 86400,
                  secure: true,
                }
              );
              cookie.save("usr-id-qt", response.data?.token, {
                path: "/",
                maxAge: 5 * 60 * 1000, //force 5 minutes
                secure: true,
                domain: ".qiktruck.co.za",
              });
              axios
                .post(baseURL + "/payment-document", {
                  orderid,
                  token: response.data?.token,
                })
                .then((response) => {
                  setTimeout(() => {
                    this.setState({
                      pdfinvoice: response.data,
                    });
                  }, 10000);
                })
                .catch((e) => {
                  console.log(e);
                  Bugsnag.notify(e);
                });
            }
          }
        );
      })
      .catch((e) => {
        this.setState({ apierror: e.message });
        Bugsnag.notify(e);
      })
      .finally(() => this.setState({ loading: false }));
  }
  cancelOrder() {
    const {
      orderid,
      email,
      quoteid,
      mobilenumber,
      orderstatus,
      sellingprice,
      collectiondate,
      dateadded,
      category,
      deliverylat,
      deliverylng,
      collectionlat,
      collectionlng,
      collectiontime,
    } = this.state.orderdetails;

    this.setState({
      loading: true,
      apierror: "",
    });

    axios
      .post(baseURL + "/customer-stop-order", {
        orderid,
        email,
        quoteid,
        mobilenumber,
        orderstatus,
        sellingprice,
        collectiondate,
        dateadded,
        category,
        deliverylat,
        deliverylng,
        collectionlat,
        collectionlng,
        collectiontime,
      })
      .then((response) => {
        window.location.reload();
      })
      .catch((e) => {
        this.setState({ apierror: e.message });
        Bugsnag.notify(e);
      })
      .finally((e) => this.setState({ loading: false }));
  }
  ringDriver() {
    const orderid = this.state.orderid;

    this.setState({
      loading: true,
      happyDriver: false,
    });

    axios
      .post(baseURL + "/ring-driver", {
        orderid,
      })
      .then((response) => {
        setTimeout(() => {
          this.setState({
            happyDriver: true,
          });
        }, 1000);
      })
      .catch((e) => {
        Bugsnag.notify(e);
      })
      .finally((e) => this.setState({ loading: false }));
  }
  componentDidMount() {
    if (this.state.paynow === "yes") this.trackOrder();
  }
  allowPayment(order) {
    return (
      order.paymentstatus !== "COMPLETE" &&
      (order.orderstatus === "accepted" ||
        order.orderstatus === "finished" ||
        order.orderstatus === "in-progress")
    );
  }
  render() {
    const { orderdetails, success, cancel, ringDriver, happyDriver } =
      this.state;

    return (
      <React.Fragment>
        <SEO
          title={
            "On demand truck and driver hire transportation service. Local truck/bakkie hire and couriers at affordable prices."
          }
          titleTemplate={"QikTruck"}
          titleSeparator={`-`}
          description={
            "Move your goods with our delivery vehicles and drivers. Furniture, Equipment, Construction Materials and more! Review your instant online quotation now. On demand truck and driver hire transportation service."
          }
          image={"https://media.qiktruck.co.za/qiktruck-preview-header.png"}
          pathname={"https://qiktruck.co.za/track-order"}
          twitterUsername={"qiktrucksa"}
        />
        <section className="hero-section" id="location">
          <Container style={{ display: "grid" }}>
            <Modal
              isOpen={ringDriver}
              onOpened={() => {
                this.ringDriver();
              }}
            >
              <ModalHeader>
                <i className={"mdi mdi-star orange"}></i>
                Ringing Driver ...
              </ModalHeader>
              <ModalBody>
                <div className="phone">
                  <i className={"mdi mdi-phone-in-talk orange"}></i>
                </div>
                {happyDriver && (
                  <p>
                    <i className={"mdi mdi-star orange"}></i>
                    Driver notification received.
                  </p>
                )}
                <p>Connecting to the driver...</p>
                <p>
                  The driver will call back once they are free for a phone call
                </p>
                <b>
                  Drivers are not allowed to accept phone calls while they are
                  on trips.
                </b>
                <br />
                <b> Use this service once every 20 minutes for urgent trips.</b>
              </ModalBody>
              <ModalFooter>
                <Button
                  color="default"
                  onClick={() => this.setState({ ringDriver: false })}
                >
                  Close
                </Button>
              </ModalFooter>
            </Modal>
            <Row>
              <Col lg={12}>
                <div className="hero-wrapper mb-4">
                  <h2 className="font-18 text-uppercase">Track your order</h2>
                  {!this.state.loading && this.state.pdfinvoice?.length > 0 ? (
                    <a
                      href={
                        "https://media.admin.qiktruck.co.za/" +
                        orderdetails.orderid +
                        ".pdf"
                      }
                      onClick={() => {
                        setTimeout(() => {
                          window.location.reload();
                        }, 100);
                      }}
                      target={"_blank"}
                      rel="noopener noreferrer"
                      className="download-invoice"
                    >
                      <button>Download PDF invoice</button>
                    </a>
                  ) : (
                    <div className="orange">Creating latest invoice ... please wait</div>
                  )}
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={12} md={12} sm={12} xs={12}>
                <FormGroup>
                  <Label for="orderid">Order Number</Label>
                  <Input
                    id="orderid"
                    name="orderid"
                    type="text"
                    defaultValue={this.state.orderid}
                    onChange={(e) => {
                      this.setState({ orderid: e.target.value });
                    }}
                  />
                </FormGroup>
              </Col>
            </Row>

            {this.state.loading === false && this.state.apierror.length > 0 && (
              <p className="error-message">{this.state.apierror}</p>
            )}
            <>
              <Button
                className="float-right mb-4"
                color="success"
                onClick={() => {
                  this.trackOrder();
                }}
                disabled={!this.validStepOne() || this.state.loading}
              >
                Track your order
                <i className="mdi mdi-arrow-down-bold ml-2"></i>
              </Button>
            </>
            <hr />
            {(success === "true" ||
              orderdetails.paymentstatus === "COMPLETE") && (
              <Alert color="success">
                Your <b> payment </b>
                was successful.
              </Alert>
            )}
            {(cancel === "true" ||
              orderdetails.paymentstatus === "CANCELLED") && (
              <Alert color="danger">
                Your <b> payment </b>
                was not processed.
              </Alert>
            )}
            {this.state.trackedOrderValid && (
              <div className="receipt">
                <br />
                <Row>
                  <Col lg={3} md={6} sm={6} xs={6}>
                    <Label>Order ID</Label>
                    <div>{orderdetails.orderid.split("-")[0]}</div>
                    <br />
                  </Col>
                  <Col lg={3} md={6} sm={6} xs={6}>
                    <Label>Status</Label>
                    <div>{orderdetails.orderstatus}</div>
                    <br />
                  </Col>

                  <Col lg={3} md={6} sm={6} xs={6}>
                    <Label>Driver details</Label>
                    <div>
                      {orderdetails.driverfirstname ||
                        (orderdetails.orderstatus !== "stopped" && (
                          <div className="looking-for-driver">
                            "Assigning Driver"
                            <br /> <br /> Please wait while we find a driver
                            near you.
                            <br />
                            The driver will call you once they accept the job.
                          </div>
                        ))}
                      <br />
                      <br />
                      {/* {("accepted" === orderdetails.orderstatus ||
                        "finished" === orderdetails.orderstatus ||
                        "assigned" === orderdetails.orderstatus ||
                        "in-progress" === orderdetails.orderstatus ||
                        "in-review" === orderdetails.orderstatus) &&
                        orderdetails.vehicleid !== null && (
                          <>
                            <a
                              className="nudge-driver sms"
                              href={`sms://${orderdetails.drivermobilenumber};?&body=Hi ${orderdetails.driverfirstname}, I made a booking for your truck with trip id ${orderdetails.orderid}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <i className="mdi mdi-email"></i> Send SMS
                            </a>

                            <button
                              className="nudge-driver call"
                              onClick={() => {
                                window.open(
                                  "tel:" + orderdetails.drivermobilenumber
                                );
                              }}
                            >
                              <i className="mdi mdi-phone"></i> Phone Call
                            </button>

                            <a
                              className="nudge-driver whatsapp"
                              href={`https://wa.me/${
                                orderdetails.drivermobilenumber[0] === "0"
                                  ? (() => {
                                      return (
                                        "+27" +
                                        orderdetails.drivermobilenumber.slice(1)
                                      );
                                    })()
                                  : orderdetails.drivermobilenumber
                              }/?text=Hi ${
                                orderdetails.driverfirstname
                              }, I made a booking for your truck with trip id ${
                                orderdetails.orderid
                              }`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <i className="mdi mdi-whatsapp"></i> Whatsapp
                            </a>
                            <button
                              className="nudge-driver buzz"
                              onClick={() => {
                                this.setState({ ringDriver: true });
                              }}
                            >
                              <i className="mdi mdi-phone-in-talk"></i> QikBuzz
                            </button>
                          </>
                        )} */}
                      <br />
                      <br />
                    </div>
                  </Col>
                  <Col lg={3} md={6} sm={6} xs={6}>
                    <Label>Vehicle type</Label>
                    <div>
                      <img
                        src={`https://media.qiktruck.co.za/${orderdetails?.trucktype}.png`}
                        alt=""
                        height={60}
                      />
                      <br />
                      {orderdetails.trucktype}
                      <br />
                      {["accepted", "in-progress"].indexOf(
                        orderdetails.orderstatus
                      ) > -1
                        ? orderdetails.licenseplatenumber
                        : ""}
                      {/* <br />
                      <Button
                        className="mb-4"
                        size="sm"
                        type="submit"
                        style={{ fontSize: "10px" }}
                        onClick={() =>
                          (window.location.href =
                            "/?v=" + orderdetails.vehicleid)
                        }
                      >
                        BOOK DRIVER AGAIN
                        <i className="mdi mdi-star  green-color ml-2"></i>
                      </Button> */}
                      <br />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg={3} md={6} sm={6} xs={6}>
                    <Label>Description</Label>
                    <div>
                      {orderdetails.firstname}, {orderdetails.lastname},{" "}
                      {/* {orderdetails.email}, {orderdetails.mobilenumber} */}
                    </div>
                    <hr />
                    <div>
                      {orderdetails?.runners} helper(s)
                      <br />
                      {orderdetails?.floors} floor(s)
                      <br />
                    </div>
                    <p>{orderdetails.description}</p>
                  </Col>
                  <Col lg={3} md={6} sm={6} xs={6}>
                    <Label>Collection date</Label>
                    <div>
                      {orderdetails.collectiondate} at{" "}
                      {orderdetails.collectiontime}
                    </div>
                  </Col>

                  <Col lg={3} md={6} sm={6} xs={6}>
                    <Label>From</Label>
                    <div>
                      <LatLngToAddress
                        lat={orderdetails.collectionlat}
                        lng={orderdetails.collectionlng}
                      />
                    </div>
                  </Col>
                  <Col lg={3} md={6} sm={6} xs={6}>
                    <Label>To</Label>
                    <div>
                      <LatLngToAddress
                        lat={orderdetails.deliverylat}
                        lng={orderdetails.deliverylng}
                      />
                    </div>
                  </Col>
                </Row>
                <hr />
                {(success === "true" ||
                  orderdetails.paymentstatus === "COMPLETE") && (
                  <Alert color="success">
                    Your <b> payment </b>
                    was successful.
                  </Alert>
                )}
                {(cancel === "true" ||
                  orderdetails.paymentstatus === "CANCELLED") && (
                  <Alert color="danger">
                    Your <b> payment </b>
                    was not processed.
                  </Alert>
                )}
                <Row>
                  <Col style={{ textAlign: "right" }} xs={12} md={12}>
                    <div>
                      <CurrencyFormat
                        value={orderdetails.sellingprice / 100}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"R"}
                        decimalScale={2}
                        style={{ fontSize: 40 }}
                      />
                    </div>
                    {orderdetails.paymentid ? (
                      "PAYMENT " + orderdetails.paymentstatus
                    ) : (
                      <form
                        action={process.env.REACT_APP_PAYMENT_URL}
                        method="post"
                      >
                        <input
                          type="hidden"
                          name="merchant_id"
                          value={
                            process.env.REACT_APP_PAYMENT_MERCHANT_PUBLIC_ID
                          }
                        />
                        <input
                          type="hidden"
                          name="merchant_key"
                          value={
                            process.env.REACT_APP_PAYMENT_MERCHANT_PUBLIC_KEY
                          }
                        />
                        <input
                          type="hidden"
                          name="amount"
                          value={orderdetails.sellingprice / 100}
                        />
                        <input
                          type="hidden"
                          name="item_name"
                          value={orderdetails.description}
                        />
                        <input
                          type="hidden"
                          name="return_url"
                          value={
                            process.env.REACT_APP_PAYMENT_SUCCESS +
                            "&email=" +
                            orderdetails.email +
                            "&orderid=" +
                            orderdetails.orderid
                          }
                        />
                        <input
                          type="hidden"
                          name="cancel_url"
                          value={
                            process.env.REACT_APP_PAYMENT_CANCEL +
                            "&email=" +
                            orderdetails.email +
                            "&orderid=" +
                            orderdetails.orderid
                          }
                        />
                        <input
                          type="hidden"
                          name="notify_url"
                          value={process.env.REACT_APP_PAYMENT_NOTIFY_URL}
                        />

                        <input
                          type="hidden"
                          name="name_first"
                          value={orderdetails.firstname}
                        />
                        <input
                          type="hidden"
                          name="name_last"
                          value={orderdetails.lastname}
                        />
                        <input
                          type="hidden"
                          name="email_address"
                          value={orderdetails.email}
                        />
                        <input
                          type="hidden"
                          name="cell_number"
                          value={orderdetails.mobilenumber}
                        />
                        <input
                          type="hidden"
                          name="email_confirmation"
                          value="1"
                        />
                        <input
                          type="hidden"
                          name="confirmation_address"
                          value="support@qiktruck.co.za"
                        />
                        <input
                          type="hidden"
                          name="m_payment_id"
                          value={orderdetails.orderid}
                        />

                        <input
                          type="hidden"
                          name="custom_str1"
                          value={orderdetails.orderstatus}
                        />
                        {this.allowPayment(orderdetails) &&
                        success !== "true" ? (
                          <Button
                            className="mb-4"
                            color="primary"
                            size="sm"
                            type="submit"
                          >
                            PAY ONLINE
                            <i className="mdi mdi-star  green-color ml-2"></i>
                            <i className="mdi mdi-credit-card ml-2"></i>
                          </Button>
                        ) : (
                          <div className="looking-for-driver">
                            Payment options only available when the driver
                            accepts the job.
                          </div>
                        )}
                      </form>
                    )}
                    <hr />
                  </Col>
                </Row>
                {this.allowPayment(orderdetails) && (
                  <Row>
                    <Col xs={12} md={6}>
                      <>
                        <div>Or pay via bank transfer</div>
                        <div>
                          FNB QIKTRUCK: <b>62934406986</b>
                        </div>
                        <div>
                          Reference: <b>{orderdetails.orderid.split("-")[0]}</b>
                        </div>
                        <br />
                      </>
                    </Col>{" "}
                  </Row>
                )}

                <br />
              </div>
            )}
          </Container>

          <div className="refresh-screen" onClick={() => this.trackOrder()}>
            REFRESH
          </div>
          <br />
          <br />

          <Row>
            <Col xs={12} md={12}>
              <center>
                <a
                  className="mb-4"
                  href="mailto:support@qiktruck.co.za"
                  color="normal"
                  size="sm"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Help via Email
                  <i className="mdi mdi-email ml-2"></i>
                </a>
                <br />
                <br />
                <a
                  href="https://wa.me/+27663784460"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="mb-4"
                  color="normal"
                  size="sm"
                >
                  Help via Whatsapp
                  <i className="mdi mdi-whatsapp ml-2"></i>
                </a>
                <br />
                <br />
              </center>
            </Col>
          </Row>
          <hr />
          {this.state.trackedOrderValid &&
            orderdetails.paymentstatus !== "COMPLETE" &&
            (this.state.orderdetails.orderstatus === "in-review" ||
              this.state.orderdetails.orderstatus === "accepted" ||
              this.state.orderdetails.orderstatus === "in-progress") && (
              <Row>
                <Col xs={12} md={12}>
                  <center>
                    <Button
                      className="mb-4 sad"
                      size="sm"
                      onClick={() => {
                        this.cancelOrder();
                      }}
                    >
                      Cancel this order?
                      <br /> (this action can not be reversed)
                      <i className="mdi mdi-alert ml-2"></i>
                    </Button>
                    {this.state.loading === false &&
                      this.state.apierror.length > 0 && (
                        <p className="error-message text-center">
                          {this.state.apierror}
                        </p>
                      )}
                  </center>
                </Col>
              </Row>
            )}
        </section>
      </React.Fragment>
    );
  }
}

export default Section;
